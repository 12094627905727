<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Ventas</h4>
            <div class="small text-muted">Administración de todo el proceso de la venta</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>            
            <b-col lg="8">
              <b-row>                        
                <b-col lg="6">
                  <Widget :configWidget="configWidget" reference="indicator_total_amount_sales_today" :hasAccess="configWidget.elements.indicatorTotalAmountSalesToday" />
                </b-col>            
                <b-col lg="6">                        
                  <Widget :configWidget="configWidget" reference="indicator_total_sales_today" :hasAccess="configWidget.elements.indicatorTotalSalesToday" />
                </b-col>
                <b-col lg="12">                        
                  <Widget :configWidget="configWidget" reference="bar_sales_months" :hasAccess="configWidget.elements.barSalesMonths" />
                </b-col>                
              </b-row>
            </b-col>
          </b-row>
        </b-col>  
        <b-col lg="3">
          
          <b-list-group class="mb-1" v-if="parameters.allowSalesTrashed">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSalesTrashed()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Ventas Provisorias</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Generación de comprobantes de ventas sin aprobación
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSales()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Ventas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Adminsitración de las ventas
              </p>
            </b-list-group-item>
          </b-list-group>
          
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCurrentsAccounts()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Cuentas Corriente</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Vista de cuentas corrientes de clientes
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSettings()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Configuración</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuración del módulo
              </p>
            </b-list-group-item>
          </b-list-group>                     

        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  //import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Param from '@/config/parameters'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalAmountSalesToday: true,
            indicatorTotalSalesToday: true,
            barSalesMonths: true,
          }
        },
        parameters: {
          allowSalesTrashed: Helper.hasParametersAccess(Param.P41),
        },              
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalAmountSalesToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_amount_sales_today')
      this.configWidget.elements.indicatorTotalSalesToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_sales_today')
      this.configWidget.elements.barSalesMonths = Helper.hasAccessWidget(this.configWidget, 'bar_sales_months')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openSales() {
        this.$router.push({ name: 'SalesStaffSales' })
      }, 
      openSettings() {
        this.$router.push({ name: 'SalesStaffSettings' })
      },
      openCurrentsAccounts() {
        this.$router.push({ name: 'SalesStaffCurrentsAccounts' })
      }, 
      openSalesTrashed() {
        this.$router.push({ name: 'SalesStaffSalesTrashed' })
      }            
    }    
   
  }
</script>

<style>

</style>
